// frontend/src/components/CloudinaryFolderSelector.jsx
import React, { useEffect, useState } from 'react';
import { getCloudinaryFolders } from '../services/api';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function CloudinaryFolderSelector({ value, onSelect }) {
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const response = await getCloudinaryFolders();
                console.log("Cloudinary folders response:", response.data);
                setFolders(response.data.folders || []);
            } catch (error) {
                console.error('Error fetching cloudinary folders:', error);
            }
        };
        fetchFolders();
    }, []);

    return (
        <FormControl fullWidth>
            <InputLabel>Cloudinary Folder</InputLabel>
            <Select
                value={value}
                onChange={(e) => onSelect(e.target.value)}
                label="Cloudinary Folder"
            >
                {folders.map((folder, idx) => (
                    <MenuItem key={idx} value={folder}>
                        {folder}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default CloudinaryFolderSelector;
