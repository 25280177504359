// frontend/src/components/SyncDashboard.jsx
import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    Box,
    Alert,
    Snackbar,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ThemeProvider,
    createTheme,
} from '@mui/material';
import SyncJobsList from './SyncJobsList';
import SyncStats from './SyncStats';
import CloudinaryFolderSelector from './CloudinaryFolderSelector';
import { createSyncJob } from '../services/api';

const SYNC_FREQUENCIES = [
    { value: 'immediate', label: 'Immédiatement (une seule fois)' },
    { value: 'hourly', label: 'Toutes les heures' },
    { value: 'daily', label: 'Quotidiennement' },
    { value: 'weekly', label: 'Hebdomadairement' },
    { value: 'monthly', label: 'Mensuellement' },
];

const theme = createTheme({
    palette: {
        primary: { main: '#031e41' },
        secondary: { main: '#ffa500' },
    },
    components: {
        MuiPaper: { styleOverrides: { root: { borderRadius: 16, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' } } },
        MuiButton: { styleOverrides: { root: { borderRadius: 8, textTransform: 'none' } } },
    },
});

function SyncDashboard() {
    const [config, setConfig] = useState({
        folders: ['/images_prod/BD_2000x2000/'],
        startDate: null,
        endDate: null,
        recurrence: 'immediate',
        cloudinaryFolder: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        const jobData = { 
            folders: config.folders, 
            recurrence: config.recurrence,
            cloudinaryFolder: config.cloudinaryFolder,
            startDate: config.startDate ? config.startDate.toISOString() : new Date().toISOString()
        };

        if (config.recurrence === 'immediate') {
            if (!config.startDate || !config.endDate) {
                setError('Please select both start and end dates for immediate jobs');
                return;
            }
            if (config.startDate > config.endDate) {
                setError('Start date must be before end date');
                return;
            }
            jobData.endDate = config.endDate.toISOString();
        }

        try {
            setLoading(true);
            await createSyncJob(jobData);
            setSuccess(true);
            setConfig({
                folders: ['/images_prod/BD_2000x2000/'],
                startDate: null,
                endDate: null,
                recurrence: 'immediate',
                cloudinaryFolder: '',
            });
        } catch (error) {
            console.error('Error creating sync job:', error);
            setError('Failed to create sync job: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1, bgcolor: '#f5f5f5', minHeight: '100vh', p: 3 }}>
                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                    <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
                <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                    <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Sync job created successfully!
                    </Alert>
                </Snackbar>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3, mb: 3, bgcolor: '#ffffff', borderRadius: 2, boxShadow: 3 }}>
                            <Typography variant="h4" gutterBottom color="primary">
                                Sync Manager
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 3, height: '100%', bgcolor: '#ffffff', borderRadius: 2, boxShadow: 3 }}>
                            <Typography variant="h5" gutterBottom color="primary">
                                New Sync Job
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Folders"
                                            value={config.folders.join(', ')}
                                            onChange={(e) => setConfig((prev) => ({ ...prev, folders: e.target.value.split(',').map((f) => f.trim()) }))}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <DatePicker
                                            label="Start Date"
                                            value={config.startDate}
                                            onChange={(date) => setConfig((prev) => ({ ...prev, startDate: date }))} 
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </Grid>

                                    {config.recurrence === 'immediate' && (
                                        <Grid item xs={6}>
                                            <DatePicker
                                                label="End Date"
                                                value={config.endDate}
                                                onChange={(date) => setConfig((prev) => ({ ...prev, endDate: date }))} 
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Frequency</InputLabel>
                                            <Select
                                                value={config.recurrence}
                                                onChange={(e) => setConfig(prev => ({ ...prev, recurrence: e.target.value }))}
                                            >
                                                {SYNC_FREQUENCIES.map((freq) => (
                                                    <MenuItem key={freq.value} value={freq.value}>
                                                        {freq.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <CloudinaryFolderSelector
                                            value={config.cloudinaryFolder}
                                            onSelect={(folder) => setConfig((prev) => ({ ...prev, cloudinaryFolder: folder }))}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={loading}
                                            fullWidth
                                            sx={{ mt: 2, fontWeight: 'bold' }}
                                        >
                                            {loading ? <CircularProgress size={24} /> : 'Create Sync Job'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 3, height: '100%', bgcolor: '#ffffff', borderRadius: 2, boxShadow: 3 }}>
                            <Typography variant="h5" gutterBottom color="primary">
                                Sync Statistics
                            </Typography>
                            <SyncStats />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3, bgcolor: '#ffffff', borderRadius: 2, boxShadow: 3 }}>
                            <Typography variant="h5" gutterBottom color="primary">
                                Sync Jobs
                            </Typography>
                            <SyncJobsList />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}

export default SyncDashboard;
