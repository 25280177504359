import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableHead, TableRow, Paper, Button,
    Dialog, DialogTitle, DialogContent, DialogActions, Typography,
    Box, Snackbar, Alert, CircularProgress, Pagination, LinearProgress, useMediaQuery
} from '@mui/material';
import {
    PlayCircleOutline as RunningIcon,
    Schedule as ScheduledIcon,
    ErrorOutline as FailedIcon,
    CheckCircleOutline as CompletedIcon,
    Visibility as ViewIcon,
    StopCircle as StopIcon,
    DeleteOutline as DeleteIcon,
    Replay as RestartIcon,
    FileDownload as DownloadIcon,
    CheckCircle as ProcessedIcon,
    Error as FailedFileIcon,
    RemoveCircleOutline as SkippedIcon,
} from '@mui/icons-material';
import { getJobLogs, stopSyncJob, deleteSyncJob, restartSyncJob } from '../services/api';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

function SyncJobsList() {
    const [jobs, setJobs] = useState([]);
    const [loadingJobs, setLoadingJobs] = useState(true);
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [selectedJobLogs, setSelectedJobLogs] = useState([]);
    const [openLogsDialog, setOpenLogsDialog] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [currentPage, setCurrentPage] = useState(1);
    const [jobTotalPages, setJobTotalPages] = useState(1);

    const isMobile = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch(`${API_URL}/sync?page=${currentPage}`);
                const data = await response.json();
                setJobs(data.jobs || []);
                setJobTotalPages(data.totalPages || 1);
                setLoadingJobs(false);
            } catch (error) {
                console.error('Error fetching jobs:', error);
                setLoadingJobs(false);
            }
        };

        fetchJobs();

        // SSE configuration for real-time updates
        const eventSource = new EventSource(`${API_URL}/sync/stream`);
        eventSource.addEventListener('jobs', (event) => {
            const data = JSON.parse(event.data);
            setJobs((prevJobs) =>
                prevJobs.map((job) =>
                    data.jobs.find((updatedJob) => updatedJob._id === job._id) || job
                )
            );
        });

        return () => {
            eventSource.close();
        };
    }, [currentPage]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleViewLogs = async (jobId) => {
        try {
            const response = await getJobLogs(jobId);
            setSelectedJobLogs(response.data.logs || []);
            setSelectedJobId(jobId);
            setOpenLogsDialog(true);
        } catch (error) {
            console.error('Error fetching job logs:', error);
        }
    };

    const handleDownloadLogs = () => {
        window.location.href = `${API_URL}/sync/${selectedJobId}/logs/download`;
    };

    const handleCloseLogs = () => {
        setOpenLogsDialog(false);
    };

    const handleStopJob = async (jobId) => {
        try {
            await stopSyncJob(jobId);
            setSnackbarMessage('Job stopped successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Failed to stop job');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleDeleteJob = async (jobId) => {
        try {
            await deleteSyncJob(jobId);
            setSnackbarMessage('Job deleted successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Failed to delete job');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleRestartJob = async (jobId) => {
        try {
            await restartSyncJob(jobId);
            setSnackbarMessage('Job restarted successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Failed to restart job');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'running':
                return <RunningIcon color="primary" />;
            case 'failed':
                return <FailedIcon color="error" />;
            case 'completed':
                return <CompletedIcon color="success" />;
            default:
                return <ScheduledIcon color="disabled" />;
        }
    };

    const renderJobRow = (job) => {
        const { stats = {} } = job;
        const progressValue = stats.totalFiles
            ? (stats.processedFiles / stats.totalFiles) * 100
            : 0;

        return (
            <TableRow key={job._id}>
                <TableCell>{new Date(job.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {getStatusIcon(job.status)}
                        <Typography sx={{ ml: 1 }}>{job.status}</Typography>
                    </Box>
                </TableCell>
                <TableCell>{job.folders.join(', ')}</TableCell>
                <TableCell>{job.recurrence}</TableCell>
                <TableCell>
                    <Typography variant="caption" display="block">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <ProcessedIcon color="success" fontSize="small" /> {`${stats.processedFiles || 0} processed`}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <SkippedIcon color="info" fontSize="small" /> {`${stats.skippedFiles || 0} skipped`}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <FailedFileIcon color="error" fontSize="small" /> {`${stats.failedFiles || 0} failed`}
                        </Box>
                    </Typography>
                    <LinearProgress variant="determinate" value={progressValue} />
                </TableCell>
                <TableCell>
                    {job.nextExecutionDate
                        ? new Date(job.nextExecutionDate).toLocaleString()
                        : 'N/A'}
                </TableCell>
                <TableCell align="center">
                    <Button onClick={() => handleViewLogs(job._id)} startIcon={<ViewIcon />} color="primary" variant="outlined">
                        View Logs
                    </Button>
                    <Button onClick={() => handleStopJob(job._id)} startIcon={<StopIcon />} color="warning" variant="outlined">
                        Stop
                    </Button>
                    <Button onClick={() => handleRestartJob(job._id)} startIcon={<RestartIcon />} color="success" variant="outlined">
                        Restart
                    </Button>
                    <Button onClick={() => handleDeleteJob(job._id)} startIcon={<DeleteIcon />} color="error" variant="outlined">
                        Delete
                    </Button>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Box sx={{ my: 3 }}>
            <Typography variant="h5" gutterBottom>
                Sync Jobs
            </Typography>
            <Paper elevation={3} sx={{ p: 3, borderRadius: 2, overflowX: 'auto' }}>
                {loadingJobs ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                        <CircularProgress />
                    </Box>
                ) : isMobile ? (
                    <Box>
                        {jobs.map((job) => (
                            <Paper key={job._id} sx={{ mb: 2, p: 2 }}>
                                <Typography><strong>Date:</strong> {new Date(job.createdAt).toLocaleString()}</Typography>
                                <Typography><strong>Status:</strong> {job.status}</Typography>
                                <Typography><strong>Folders:</strong> {job.folders.join(', ')}</Typography>
                                <Typography><strong>Recurrence:</strong> {job.recurrence}</Typography>
                                <Typography><strong>Progress:</strong></Typography>
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <ProcessedIcon color="success" fontSize="small" /> {`${job.stats?.processedFiles || 0} processed`}
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <SkippedIcon color="info" fontSize="small" /> {`${job.stats?.skippedFiles || 0} skipped`}
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <FailedFileIcon color="error" fontSize="small" /> {`${job.stats?.failedFiles || 0} failed`}
                                    </Box>
                                </Box>
                                <Typography><strong>Next Execution:</strong> {job.nextExecutionDate ? new Date(job.nextExecutionDate).toLocaleString() : 'N/A'}</Typography>
                                <Box sx={{ mt: 1 }}>
                                    <Button onClick={() => handleViewLogs(job._id)} startIcon={<ViewIcon />} color="primary" variant="outlined">View Logs</Button>
                                    <Button onClick={() => handleStopJob(job._id)} startIcon={<StopIcon />} color="warning" variant="outlined">Stop</Button>
                                    <Button onClick={() => handleRestartJob(job._id)} startIcon={<RestartIcon />} color="success" variant="outlined">Restart</Button>
                                    <Button onClick={() => handleDeleteJob(job._id)} startIcon={<DeleteIcon />} color="error" variant="outlined">Delete</Button>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Folders</TableCell>
                                <TableCell>Recurrence</TableCell>
                                <TableCell>Progress</TableCell>
                                <TableCell>Next Execution</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{jobs.map(renderJobRow)}</TableBody>
                    </Table>
                )}
                <Pagination
                    count={jobTotalPages}
                    page={currentPage}
                    onChange={(e, page) => setCurrentPage(page)}
                    sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                />
            </Paper>

            <Dialog open={openLogsDialog} onClose={handleCloseLogs} maxWidth="md" fullWidth>
                <DialogTitle>Job Logs</DialogTitle>
                <DialogContent>
                    {selectedJobLogs.length > 0 ? (
                        selectedJobLogs.map((log, index) => (
                            <Typography key={index} variant="body2" gutterBottom>
                                {log.message}
                            </Typography>
                        ))
                    ) : (
                        <Typography>No logs available</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDownloadLogs} startIcon={<DownloadIcon />} color="secondary">
                        Download Logs
                    </Button>
                    <Button onClick={handleCloseLogs} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default SyncJobsList;
