// frontend/src/services/api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

export const createSyncJob = (jobData) => {
    return axios.post(`${API_URL}/sync`, {
        ...jobData,
        startDate: jobData.startDate ? jobData.startDate : new Date().getTime()
    });
};

export const getJobLogs = (id, page = 1, limit = 10) => {
    return axios.get(`${API_URL}/sync/${id}/logs`, { params: { page, limit } });
};

export const stopSyncJob = (id) => {
    return axios.put(`${API_URL}/sync/${id}/stop`);
};

export const deleteSyncJob = (id) => {
    return axios.delete(`${API_URL}/sync/${id}`);
};

export const restartSyncJob = (id) => {
    return axios.post(`${API_URL}/sync/${id}/restart`);
};

export const getCloudinaryFolders = () => {
    return axios.get(`${API_URL}/cloudinary/folders/root`);
};
