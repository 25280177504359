// frontend/src/components/SyncStats.jsx
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import {
    Description as TotalIcon,
    CheckCircleOutline as ProcessedIcon,
    RemoveCircleOutline as SkippedIcon,
    ErrorOutline as FailedIcon,
    Percent as SuccessRateIcon,
} from '@mui/icons-material';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

function SyncStats() {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const es = new EventSource(`${API_URL}/sync/stream`);
        es.addEventListener('jobs', (e) => {
            const data = JSON.parse(e.data);
            setJobs(data.jobs || []);
        });
        es.onerror = (err) => {
            console.error('Error in SSE for jobs (stats):', err);
        };
        return () => { es.close(); };
    }, []);

    const totalFiles = jobs.reduce((sum, job) => sum + (job.stats?.totalFiles || 0), 0);
    const processedFiles = jobs.reduce((sum, job) => sum + (job.stats?.processedFiles || 0), 0);
    const skippedFiles = jobs.reduce((sum, job) => sum + (job.stats?.skippedFiles || 0), 0);
    const failedFiles = jobs.reduce((sum, job) => sum + (job.stats?.failedFiles || 0), 0);
    const successRate = totalFiles ? ((processedFiles / totalFiles) * 100).toFixed(1) : 0;

    return (
        <Box sx={{ mb: 3 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 2, fontWeight: 'bold', color: '#031e41' }}>
                Synchronization Statistics
            </Typography>
            <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                <StatBox title="Total Files" value={totalFiles} color="primary" icon={<TotalIcon />} />
                <StatBox title="Processed" value={processedFiles} color="success" icon={<ProcessedIcon />} />
                <StatBox title="Skipped" value={skippedFiles} color="info" icon={<SkippedIcon />} />
                <StatBox title="Failed" value={failedFiles} color="error" icon={<FailedIcon />} />
                <StatBox title="Success Rate" value={`${successRate}%`} color="secondary" icon={<SuccessRateIcon />} />
            </Grid>
        </Box>
    );
}

const StatBox = ({ title, value, color, icon }) => (
    <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 2,
                backgroundColor: `${color}.main`,
                color: 'white',
                boxShadow: 3,
                minWidth: '100px',
                textAlign: 'center',
            }}
        >
            <Box sx={{ fontSize: 30, mb: 1 }}>{icon}</Box>
            <Typography variant="h6" color="inherit" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                {title}
            </Typography>
            <Typography variant="h4" color="inherit" sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                {value}
            </Typography>
        </Paper>
    </Grid>
);

export default SyncStats;
